var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "#ffffff" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "风险信息",
                },
                on: {
                  "head-save": _vm.headSave,
                  "head-complete": _vm.headComplete,
                  "head-cancel": _vm.headCancel,
                },
              }),
              _c(
                "div",
                { staticStyle: { padding: "12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "160px",
                        disabled: _vm.type == "detail",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "风险编号:",
                                    prop: "riskCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "text",
                                        placeholder: "请输入风险编号",
                                        maxlength: "64",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value: _vm.ruleForm.riskCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "riskCode",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.riskCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    自动生成\n                    "
                                          ),
                                          _c("el-switch", {
                                            on: { change: _vm.handleSwitch },
                                            model: {
                                              value: _vm.getRiskCode,
                                              callback: function ($$v) {
                                                _vm.getRiskCode = $$v
                                              },
                                              expression: "getRiskCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "风险描述:",
                                    prop: "riskName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "请输入风险描述",
                                    },
                                    model: {
                                      value: _vm.ruleForm.riskName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "riskName", $$v)
                                      },
                                      expression: "ruleForm.riskName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "风险源:",
                                    prop: "sourceCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择风险源",
                                        clearable: "",
                                      },
                                      on: { change: _vm.handleSource },
                                      model: {
                                        value: _vm.ruleForm.sourceCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "sourceCode",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.sourceCode",
                                      },
                                    },
                                    _vm._l(_vm.riskSourceList, function (item) {
                                      return _c("el-option", {
                                        key: item.sourceCode,
                                        attrs: {
                                          label: item.sourceName,
                                          value: item.sourceCode,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "事故类型:", prop: "event" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        multiple: "",
                                        placeholder: "请选择事故类型",
                                      },
                                      model: {
                                        value: _vm.ruleForm.event,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "event", $$v)
                                        },
                                        expression: "ruleForm.event",
                                      },
                                    },
                                    _vm._l(_vm.eventList, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "deptIdList",
                                    label: "责任部门",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择责任部门",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.handleDeptModeOpenForm()
                                      },
                                    },
                                    model: {
                                      value: _vm.ruleForm.responsibleDep,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "responsibleDep",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.responsibleDep",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "后果影响描述:",
                                    prop: "consequence",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入内容",
                                    },
                                    model: {
                                      value: _vm.ruleForm.consequence,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "consequence",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.consequence",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "L:" } },
                                [
                                  _c("el-input-number", {
                                    attrs: { min: 0 },
                                    on: { input: _vm.handleChange },
                                    model: {
                                      value: _vm.ruleForm.lecdLScore,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "lecdLScore",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.lecdLScore",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "E:" } },
                                [
                                  _c("el-input-number", {
                                    attrs: { min: 0 },
                                    on: { input: _vm.handleChange },
                                    model: {
                                      value: _vm.ruleForm.lecdEScore,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "lecdEScore",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.lecdEScore",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "C:" } },
                                [
                                  _c("el-input-number", {
                                    attrs: { min: 0 },
                                    on: { input: _vm.handleChange },
                                    model: {
                                      value: _vm.ruleForm.lecdCScore,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "lecdCScore",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.lecdCScore",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticClass: "class-D", attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "D:" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "calc(100% - 30px)" },
                                    attrs: {
                                      min: 0,
                                      default: "0",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.ruleForm.lecdDScore,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "lecdDScore",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.lecdDScore",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "LEC风险评价法：L（Likelihood，事故发生的可能性）、E（Exposure，人员暴露于危险环境中的频繁程度）、C（Consequence，一旦发生事故可能造成的后果）。给三种因素的不同等级分别确定不同的分值，再以三个分值的乘积D（danger，危险性）来评价风险的大小。注：自行选择风险等级后D值会清空，采用用户的选择",
                                        placement: "right-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                        staticStyle: {
                                          "margin-left": "10px",
                                          "font-size": "20px",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "风险等级:" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder:
                                          "系统自动计算（也可自行选择）",
                                        clearable: "",
                                      },
                                      on: { change: _vm.riskLevelChange },
                                      model: {
                                        value: _vm.ruleForm.riskLevel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "riskLevel",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.riskLevel",
                                      },
                                    },
                                    _vm._l(_vm.D, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.levelName,
                                          value: item.levelName,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "工程技术",
                                    prop: "treatEngineer",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 3 },
                                      "show-word-limit": "",
                                      maxlength: "1000",
                                      placeholder: "请输入工程技术内容",
                                    },
                                    model: {
                                      value: _vm.ruleForm.treatEngineer,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "treatEngineer",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.treatEngineer",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "管理措施",
                                    prop: "treatManagment",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 3 },
                                      "show-word-limit": "",
                                      maxlength: "1000",
                                      placeholder: "请输入管理措施内容",
                                    },
                                    model: {
                                      value: _vm.ruleForm.treatManagment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "treatManagment",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.treatManagment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "培训教育",
                                    prop: "treatTraining",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 3 },
                                      "show-word-limit": "",
                                      maxlength: "1000",
                                      placeholder: "请输入培训教育内容",
                                    },
                                    model: {
                                      value: _vm.ruleForm.treatTraining,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "treatTraining",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.treatTraining",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "个体防护",
                                    prop: "treatProtection",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 3 },
                                      "show-word-limit": "",
                                      maxlength: "1000",
                                      placeholder: "请输入个体防护内容",
                                    },
                                    model: {
                                      value: _vm.ruleForm.treatProtection,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "treatProtection",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.treatProtection",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "应急处置",
                                    prop: "treatEmergency",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 3 },
                                      "show-word-limit": "",
                                      maxlength: "1000",
                                      placeholder: "请输入个体防护内容",
                                    },
                                    model: {
                                      value: _vm.ruleForm.treatEmergency,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "treatEmergency",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.treatEmergency",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("userList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.nowRuleFormId,
            expression: "this.nowRuleFormId",
          },
        ],
        ref: "userList",
        attrs: { riskLibraryId: this.nowRuleFormId },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.personShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.personShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptShow, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: { selection: true },
            on: {
              "select-all-data": _vm.selectData1,
              "select-data": _vm.selectRowData1,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptShowForm, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShowForm = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            on: { "select-data": _vm.selectData2 },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }